'use client';

import { Image } from '@akinon/next/components/image';
import { useLocalization } from '@akinon/next/hooks';
import { Link } from '@theme/components';

export default function NotFound() {
  const { t } = useLocalization();
  return (
    <div className="bg-plum-cheese relative min-h-[80vh] flex flex-col justify-center items-center px-4">
      <Image
        className="absolute hidden lg:block left-[4rem] bottom-0"
        src="/404-bottom-image.png"
        alt="404"
        width={73}
        height={85}
      />
      <Image
        className="absolute left-0 top-0 w-1/2 4xl:w-max"
        imageClassName='4xl:w-[76.625rem]'
        src="/404-star.png"
        alt="404"
        width={1226}
        height={1226}
      />
      <Image
        className="absolute right-0 bottom-0 w-1/2 translate-y-1/2 lg:right-8 lg:translate-y-3/4  4xl:w-max"
        imageClassName='4xl:w-[65.3125rem]'
        src="/404-ellipse.png"
        alt="404"
        width={1172}
        height={1172}
      />
      <div className="text-white flex flex-col items-center gap-y-7 lg:gap-y-[3.75rem] py-10 z-[2]">
        <Image src="/404.png" alt="404" width={200} height={200} imageClassName='w-[19.5625rem] h-[7.8125rem]' />
        <div>
          <h1 className="mb-4 text-center text-[2rem] leading-10 font-kronaOne">
            {t('not_found.title')}
          </h1>
          <p className="text-center text-base">{t('not_found.description')}</p>
        </div>
        <Link
          href="/"
          className="w-full max-w-[19.75rem] py-[.875rem] bg-white text-center text-black font-kronaOne rounded-[2rem]"
        >
          {t('not_found.button')}
        </Link>
      </div>
    </div>
  );
}
